@if $enable-roboto-webfont {

  //*// WEBFONTS //*//
  $roboto-base-path: "../roboto/" !default;

  // 300 Light
  @include font-face($name: "Roboto",
    $local: "Roboto Light",
    $svg-id: "robotolight",
    $path: $roboto-base-path + "roboto-light",
    $weight: 300,
    $style: normal);

  // 300 Light Italic
  @include font-face($name: "Roboto",
    $local: "Roboto Light Italic",
    $svg-id: "robotolight_itfalse",
    $path: $roboto-base-path + "roboto-lightitalic",
    $weight: 300,
    $style: italic);

  // 400 Regular
  @include font-face($name: "Roboto",
    $local: "Roboto Regular",
    $svg-id: "robotoregular",
    $path: $roboto-base-path + "roboto-regular",
    $weight: 400,
    $style: normal);

  // 400 Italic
  @include font-face($name: "Roboto",
    $local: "Roboto Italic",
    $svg-id: "robotoitalic",
    $path: $roboto-base-path + "roboto-italic",
    $weight: 400,
    $style: italic);

  // 500 Medium
  @include font-face($name: "Roboto",
    $local: "Roboto Medium",
    $svg-id: "robotomedium",
    $path: $roboto-base-path + "roboto-medium",
    $weight: 500,
    $style: normal);

  // 500 Medium Italic
  @include font-face($name: "Roboto",
    $local: "Roboto Medium Italic",
    $svg-id: "robotomedium_italic",
    $path: $roboto-base-path + "roboto-mediumitalic",
    $weight: 500,
    $style: italic);

  // 700 Bold
  @include font-face($name: "Roboto",
    $local: "Roboto Bold",
    $svg-id: "robotobold",
    $path: $roboto-base-path + "roboto-bold",
    $weight: 700,
    $style: normal);

  // 700 Bold Italic
  @include font-face($name: "Roboto",
    $local: "Roboto Bold Italic",
    $svg-id: "robotobold_italic",
    $path: $roboto-base-path + "roboto-bolditalic",
    $weight: 700,
    $style: italic);

  // 300 Light Condensed (toolbar)
  @include font-face($name: "Roboto Condensed",
    $local: "Roboto Condensed Light",
    $svg-id: "roboto_condensedlight",
    $path: $roboto-base-path + "robotocondensed-light",
    $weight: 300,
    $style: normal);

  // 300 Light Condensed Italic (toolbar)
  @include font-face($name: "Roboto Condensed",
    $local: "Roboto Condensed Thin Italic",
    $svg-id: "roboto_condensedlight_italic",
    $path: $roboto-base-path + "robotocondensed-lightitalic",
    $weight: 300,
    $style: italic);

}